<template>
  <div>
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center py-10>
      <v-flex xs12 sm10>
        <v-card>
          <v-layout wrap justify-center py-5>
            <v-flex xs11 sm10>
              <v-layout wrap>
                <v-flex xs12 text-center>
                  <span class="itemHeading">LEAVE FORM</span>
                </v-flex>
              </v-layout>
              <v-layout wrap pt-5>
                <v-flex>
                  <span class="itemHeading2">
                    Total Leaves: {{ totalLeaves }}</span>
                </v-flex>
                <v-flex>
                  <span class="itemHeading2">
                    Leaves Taken: {{ leavesTaken }}</span>
                </v-flex>
                <v-flex>
                  <v-dialog v-model="dialogDetails" max-width="80%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small v-on="on" v-bind="attrs" color="#005f32" dark><span
                          style="font-family: kumbhRegular">Leave Details</span></v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center pa-4 style="background: #005f32" text-left>
                          <span class="kumbhBold" style="color: #ffffff">LEAVE DETAILS</span>
                        </v-flex>
                        <v-flex xs12 align-self-center class="kumbhBold">
                          <v-layout wrap pa-4>
                            <v-flex xs12 sm12 v-for="info in leaveCategories" :key="info._id">
                              <v-layout wrap>
                                <v-flex xs12 sm6 md3>
                                  <v-layout wrap>
                                    <v-flex xs12>
                                      <span style="color: #005f32">Category</span>
                                    </v-flex>
                                    <v-flex xs12>
                                      <span>{{ info.leaveCategory }}</span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 sm6 md3>
                                  <v-layout wrap>
                                    <v-flex xs12>
                                      <span style="color: #005f32">Total Leaves</span>
                                    </v-flex>
                                    <v-flex xs12>
                                      <span>{{ info.totalLeaves }}</span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex xs12 sm6 md3>
                                  <v-layout wrap>
                                    <v-flex xs12>
                                      <span style="color: #005f32">Balance Leave</span>
                                    </v-flex>
                                    <v-flex xs12>
                                      <span>{{ info.totalLeaves - info.availedLeaves }}</span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex xs12 sm6 md3>
                                  <v-layout wrap>
                                    <v-flex xs12>
                                      <span style="color: #005f32">Leaves Taken</span>
                                    </v-flex>
                                    <v-flex xs12>
                                      <span>{{ info.availedLeaves }}</span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex>
                  <v-dialog v-model="dialogFestival" max-width="80%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small v-on="on" v-bind="attrs" color="#005f32" dark>
                        <span style="font-family: kumbhRegular">Festival Leaves</span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center pa-4 style="background: #005f32" text-left>
                          <span class="kumbhBold" style="color: #ffffff">FESTIVAL LEAVES</span>
                        </v-flex>
                        <v-flex xs12 align-self-center pl-8 pt-4 text-left>
                          <div style="display: flex; align-items: center; gap: 16px; font-size: 16px;">
                            <!-- Green Dot for Office Open -->
                            <span style="display: flex; align-items: center;">
                              <span style="
                                  height: 10px;
                                  width: 10px;
                                  background-color: #28a745;
                                  border-radius: 50%;
                                  display: inline-block;
                                  margin-right: 8px;
                                "></span>
                              <span class="kumbhBold">Office Open</span>
                            </span>

                            <!-- Red Dot for Office Closed -->
                            <span style="display: flex; align-items: center;">
                              <span style="
                                  height: 10px;
                                  width: 10px;
                                  background-color: red;
                                  border-radius: 50%;
                                  display: inline-block;
                                  margin-right: 8px;
                                "></span>
                              <span class="kumbhBold">Office Closed</span>
                            </span>
                          </div>
                        </v-flex>

                        <v-flex xs12 pb-4 align-self-center class="kumbhBold">
                          <v-sheet height="64">
                            <v-toolbar flat>
                              <v-btn fab text small color="grey darken-2" @click="prev">
                                <v-icon small>mdi-chevron-left</v-icon>
                              </v-btn>
                              <v-btn fab text small color="grey darken-2" @click="next">
                                <v-icon small>mdi-chevron-right</v-icon>
                              </v-btn>
                              <v-toolbar-title>
                                {{ monthYearTitle }}
                              </v-toolbar-title>
                            </v-toolbar>
                          </v-sheet>
                          <v-sheet height="600">
                            <v-calendar ref="calendar" v-model="focus" color="success" :events="events" :type="type"
                              @input="updateYear" />
                          </v-sheet>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center pt-5>
                <v-flex xs12 text-left>
                  <span class="itemText2">Apply leave for</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap pt-5 class="itemText2">
                    <v-flex xs2 sm2>
                      <v-checkbox id="me" v-model="appliedBy" label="Me" value="me"></v-checkbox>
                    </v-flex>
                    <v-flex xs2 sm2>
                      <v-checkbox id="others" v-model="appliedBy" label="Others" value="others"></v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm12 text-left v-if="appliedBy == 'others'">
                  <span class="itemText2">Please Select a team Member</span>
                  <v-autocomplete v-model="uid" :items="teamMembers" dense outlined :search-input.sync="search"
                    item-text="name" item-value="_id"></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 text-left pr-2 pl-0 pl-xs-0 pl-sm-2 pl-md-2 pl-lg-0 pl-xl-0>
                  <span class="itemText2">Category</span>
                  <v-select v-model="leaveCategory" :items="leaveCategories" item-text="leaveCategory"
                    item-value="leaveCategory" dense outlined></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 text-left pr-0 pr-xs-0 pr-sm-0 pr-md-2 pr-lg-0 pr-xl-0>
                  <span class="itemText2">Type</span>
                  <v-select 
                    v-model="leaveType" 
                    :items="filteredLeaveTypes" 
                    item-text="name" 
                    item-value="value" 
                    dense 
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 text-left pr-0 pr-xs-0 pr-sm-2 pr-md-2 pr-lg-0 pr-xl-0>
                  <span class="itemText2">Choose the Dates of Leave</span>
                  <v-btn v-if="leaveCategory !== 'Casual' && leaveCategory !== 'Festival' && dateArray.length" text color="red" @click="clearDates">
                    Delete All
                  </v-btn>
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dateArray"
                    transition="scale-transition" offset-y min-width="auto">
                    
                    <!-- Activator for the date picker (combobox) -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox 
                        v-model="formattedDateArray" 
                        multiple 
                        class="custom-chips" 
                        chips 
                        small-chips 
                        outlined 
                        dense 
                        readonly
                        v-bind="attrs" 
                        v-on="on"
                        @click="menu = !menu" 
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                          v-if="item"
                          :close="leaveCategory === 'Casual' || leaveCategory === 'Festival'" 
                          @click:close="(leaveCategory === 'Casual' || leaveCategory === 'Festival') ? removeDate(index) : clearDates()"
                          class="ma-2"
                          outlined
                        >
                          {{ item }}
                        </v-chip>
                        
                        </template>
                      </v-combobox>
                    </template>
          
                    <!-- 'Delete All' button placed outside of combobox -->
                  
               
                
                    <!-- Date Picker Component -->
                    <v-date-picker v-model="dateArray"  @input="handleDateSelection" multiple no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(dateArray)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                
                
                
                <v-flex xs12 text-left>
                  <span class="itemText2">Reason for Leave</span>
                  <v-textarea v-model="reason" outlined></v-textarea>
                </v-flex>
                <v-flex xs12 py-5>
                  <v-btn color="#005f32" dark @click="validateInput()"><span
                      style="font-family: kumbhRegular">Submit</span></v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      festivalDates: [],
      holidays: [],
      holidayMap: {},
      dialogFestival: false,
      selectedYear: new Date().getFullYear(),
      visible: false,
      multi: true,
      showsnackbar: false,
      orgId: localStorage.getItem("orgid"),
      userId: localStorage.getItem("userid"),
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      appliedBy: "me",
      uid: null,
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      dateArray: [],
      search: "",
      value: "",
      empID: "",
      fullName: "",
      email: "",
      phone: "",
      message: "",
      msg: "",
      leaveType: "Fullday",
      totalLeaves: null,
      leavesTaken: 0,
      leaveRequiredOn: "",
      leaveCategory: "",
      reason: "",
      leaveCategories: [],
      leaveTypes: [{ name: "Full Day", value: "Fullday" }, { name: "First Half", value: "FN" }, { name: "Second Half", value: "AN" }],
      datepicker: [],
      teamMembers: [],
      isDateCorrect: true,
      dialogDetails: false,
      monthYearTitle: "",
      dates: [],
      menu: false,
      // minDate: new Date().toISOString().split('T')[0], // Format date to YYYY-MM-DD
      focus: new Date().toISOString().substr(0, 10), // Set to current date
      // events: [], // Festival data will be fetched and added here
      type: 'month', // Calendar view type
    };
  },
  computed: {
    // formattedDateArray() {
    //   return this.dateArray.map(date => {
    //     const dt = new Date(date);
    //     const day = String(dt.getDate()).padStart(2, '0');
    //     const month = String(dt.getMonth() + 1).padStart(2, '0');
    //     const year = dt.getFullYear();
    //     return `${day}-${month}-${year}`;
    //   });
    // },
  //   allowedDates() {
  //   if (this.leaveCategory === "Special") {
  //     const selectedDate = this.dateArray.length === 1 ? new Date(this.dateArray[0]) : null;
  //     if (selectedDate) {
  //       const allowedDates = [];
  //       for (let i = 0; i < 30; i++) {
  //         const nextDate = new Date(selectedDate);
  //         nextDate.setDate(selectedDate.getDate() + i);
  //         allowedDates.push(nextDate.toISOString().split("T")[0]);
  //       }
  //       return date => allowedDates.includes(date);
  //     }
  //   }
  //   return () => true; // Allow all dates if not "Special"
  // },
    formattedDateArray() {
    return this.dateArray.map(date => {
      const dt = new Date(date);
      const day = String(dt.getDate()).padStart(2, '0');
      const month = String(dt.getMonth() + 1).padStart(2, '0');
      const year = dt.getFullYear();
      return `${day}-${month}-${year}`;
    });
  },
    filteredLeaveTypes() {
    // Check if leaveCategory matches "Special," "Maternity," or "Paternity"
    const restrictedCategories = ["Special", "Maternity", "Paternity"];
    if (restrictedCategories.includes(this.leaveCategory)) {
      return this.leaveTypes.filter(type => type.value === "Fullday");
    }
    // Otherwise, return all leaveTypes
    return this.leaveTypes;
  },
    events() {
      return this.holidays.map((festival) => ({
        start: festival.festivalDate.slice(0, 10),
        end: festival.festivalDate.slice(0, 10),
        name: `${festival.festivalName} (${festival.isOfficeOpen ? "Office Open" : "Office Closed"})`,
        color: festival.isOfficeOpen ? "#28a745" : "#d9534f", // Green for open, red for closed
      }));
    },
    // events() {
    //   return this.holidays.map(festival => ({
    //     start: festival.festivalDate.slice(0,10),
    //     end: festival.festivalDate.slice(0,10),
    //     name: festival.festivalName,
    //     // details: festival.details,
    //     color: '#005f32' // Set a color for the event
    //   }));
    // },
    // filteredLeaveTypes() {
    //   // Disable "First Half" and "Second Half" if multiple dates are selected
    //   if (this.dateArray.length > 1) {
    //     return this.leaveTypes.filter(type => type.value === "Fullday");
    //   }
    //   return this.leaveTypes;
    // }

    // holidayEvents() {
    //   return this.holidays.map((festivalDay) => {
    //     const date = new Date(festivalDay.festivalDate).toISOString().split("T")[0];
    //     return {
    //       date,
    //       festivalName: festivalDay.festivalName,
    //       color: "red", // Highlight the day in red
    //     };
    //   });
    // },
  },
  // computed: {
  //   totalLeaves() {
  //     var tl = 0;
  //     for (var i = 0; i < this.leaveCategories.length; i++) {
  //       if (this.leaveCategory == this.leaveCategories[i].leaveCategory)
  //         tl = this.leaveCategories[i].totalLeaves;
  //     }
  //     return tl;
  //   },
  //   leavesTaken() {
  //     var tl = 0;
  //     for (var i = 0; i < this.leaveCategories.length; i++) {
  //       if (this.leaveCategory == this.leaveCategories[i].leaveCategory)
  //         tl = this.leaveCategories[i].availedLeaves;
  //     }
  //     return tl;
  //   },
  // },
  watch: {
    // leaveType() {
    //   this.handleDateSelection();
    // },
    leaveCategory() {
      this.clearDates();
    },
    // dateArray() {
    //   this.handleDateSelection();
    //   if (this.dateArray.length > 1 && (this.leaveType === 'FN' || this.leaveType === 'AN')) {
    //     this.leaveType = 'Fullday';  // Reset to Fullday if multiple dates selected
    //   }
    // },
    dateArray(newVal) {
      this.formattedDateArray = newVal.map((date) => {
        const d = new Date(date);
        return d.toLocaleDateString(); // Format dates as needed
      });
    },
    uid() {
      this.getleaves();
    },
    appliedBy() {
      if (this.appliedBy == "me") {
        this.uid = ""
      }
      if (this.appliedBy == "others") {
        this.leavesTaken = 0;
      }
      else { this.leavesTaken = 0; }
    },
    search(val) {
      this.loading = true;
      setTimeout(() => {
        this.getTeamList(val);
        this.loading = false;
      }, 500);
    },
  },
  mounted() {
    this.getleaves();
    this.getTeamList();
    // this.fetchHolidays(this.selectedYear);
    this.updateYear();
  },
  methods: {
  //   removeDate(index) {
  //   // Remove the date from the dateArray based on the index of the chip
  //   this.dateArray.splice(index, 1);
  // },
  removeDate(index) {
    if(this.leaveCategory=="Casual" ||this.leaveCategory=="Festival"){
      this.dateArray.splice(index, 1);
    }
     else if(this.leaveCategory=="Special" && this.leaveCategory=="Maternity"){
      if (index >= 0) {
        const removedDate = this.dateArray[index];
        const startDate = new Date(removedDate);
        this.dateArray = Array.from({ length: 30 }, (_, i) => {
          const date = new Date(startDate);
          date.setDate(date.getDate() + i);
          return date.toISOString().substr(0, 10);
        });
      }
     }
     
    },
    clearDates() {
    this.dateArray = []; // Clear selected dates
    // this.formattedDateArray = []; 
  },
    //   onMonthChange(dates) {
    //   if (dates && dates.length > 0) {
    //     // Get the year from the first selected date
    //     const selectedYear = new Date(dates[0]).getFullYear();

    //     // Call the method to fetch data with the selected year
    //     this.fetchHolidays(selectedYear);
    //   }
    // },
    // Set the calendar to today's date

    prev() {
      this.$refs.calendar.prev();
      this.updateYear();
    },
    // Move to the next period
    next() {
      this.$refs.calendar.next();
      this.updateYear();
    },
    fetchHolidays(year) {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/festival/getalllist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: { year },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.holidays = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.error(err);
        });
    },

    // Display event details
    // showEvent({ event }) {
    //   console.log('Clicked event:', event);
    // },
    // Capture the selected year and fetch festivals
    updateYear() {
      const selectedDate = new Date(this.focus);
      const year = selectedDate.getFullYear();
      const month = selectedDate.toLocaleString("default", { month: "long" });
      this.monthYearTitle = `${month} ${year}`; // Update month and year title
      this.fetchHolidays(year); // Fetch holidays for the selected year
    },
    // View day details when clicked

    createHolidayMap() {
      this.holidayMap = this.holidays.reduce((map, festivalDay) => {
        const dateStr = festivalDay.festivalDate.slice(0, 10); // Format as YYYY-MM-DD
        if (!map[dateStr]) {
          map[dateStr] = [];
        }
        map[dateStr].push(festivalDay);
        return map;
      }, {});
    },
    // isHoliday(day) {
    //   const dateStr = day.date.toISOString().slice(0, 10);
    //   return this.holidayMap[dateStr] && this.holidayMap[dateStr].length > 0;
    // },
    // getEventColor(day) {
    //   const dateStr = day.date.toISOString().slice(0, 10);
    //   return this.holidayMap[dateStr] ? "red" : "";
    // },
    // getFestivals(day) {
    //   const dateStr = day.date.toISOString().slice(0, 10);
    //   return this.holidayMap[dateStr] || [];
    // },
    validateInput() {
      if (this.appliedBy == 'others') {
        if (!this.uid) {
          this.msg = "Please select team member";
          this.showsnackbar = true;
          return;
        }
        else{
        this.applyLeave();
        }
      }
      else if (!this.dateArray.length > 0) {
        this.msg = "Please select date";
        this.showsnackbar = true;
        return;
      } else if (!this.leaveType) {
        this.msg = "Please select leave type";
        this.showsnackbar = true;
        return;
      } else if (!this.leaveCategory) {
        this.msg = "Please select category";
        this.showsnackbar = true;
        return;
      } else if (!this.reason) {
        this.msg = "Please provide reason";
        this.showsnackbar = true;
        return;
      } else {
        this.applyLeave();
      }
    },
    // allowedDates(date) {
    //   const today = new Date();
    //   const selectedDate = new Date(date);
    //   return selectedDate >= today;
    // },
    handleDateSelection(selectedDates) {
    for (var i = 0; i < this.leaveCategories.length; i++) {
      if (this.leaveCategory === this.leaveCategories[i].leaveCategory) {
        if (this.leaveCategory === "Special") {
  if (selectedDates.length === 1) {
    // If only one date is selected, create a 30-day range starting from this date
    const startDate = new Date(selectedDates[0]);
    this.dateArray = Array.from({ length: 30 }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      return date.toISOString().substr(0, 10);
    });
  } else if (selectedDates.length < this.dateArray.length) {
    // If the number of selected dates has decreased (a date was unselected)
    const unselectedDate = selectedDates.find(
      (date) => !this.dateArray.includes(date)
    );

    if (unselectedDate) {
      // Recalculate the range from the unselected date
      const startDate = new Date(unselectedDate);
      this.dateArray = Array.from({ length: 30 }, (_, i) => {
        const date = new Date(startDate);
        date.setDate(date.getDate() + i);
        return date.toISOString().substr(0, 10);
      });
    }
  } else if (selectedDates.length > 1) {
    // If multiple dates are selected, update the range starting from the last selected date
    const lastSelected = selectedDates[selectedDates.length - 1];
    const startDate = new Date(lastSelected);
    this.dateArray = Array.from({ length: 30 }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      return date.toISOString().substr(0, 10);
    });
  }
}

        // Additional conditions for Maternity/Paternity leave
        else if (this.leaveCategory === "Maternity") {
          if (selectedDates.length === 1) {
        // Select 30 continuous days starting from the first selected date
        const startDate = new Date(selectedDates[0]);
        this.dateArray = Array.from({ length: 180 }, (_, i) => {
          const date = new Date(startDate);
          date.setDate(date.getDate() + i);
          return date.toISOString().substr(0, 10);
        });
      } else if (selectedDates.length > 1) {
        // Unselecting a day: Start new range from the unselected day
        const lastSelected = selectedDates[selectedDates.length - 1];
        const startDate = new Date(lastSelected);
        this.dateArray = Array.from({ length: 180 }, (_, i) => {
          const date = new Date(startDate);
          date.setDate(date.getDate() + i);
          return date.toISOString().substr(0, 10);
        });
      }
        } else if (this.leaveCategory === "Paternity") {
          if (selectedDates.length === 1) {
        // Select 30 continuous days starting from the first selected date
        const startDate = new Date(selectedDates[0]);
        this.dateArray = Array.from({ length: 15 }, (_, i) => {
          const date = new Date(startDate);
          date.setDate(date.getDate() + i);
          return date.toISOString().substr(0, 10);
        });
      } else if (selectedDates.length > 1) {
        // Unselecting a day: Start new range from the unselected day
        const lastSelected = selectedDates[selectedDates.length - 1];
        const startDate = new Date(lastSelected);
        this.dateArray = Array.from({ length: 15}, (_, i) => {
          const date = new Date(startDate);
          date.setDate(date.getDate() + i);
          return date.toISOString().substr(0, 10);
        });
      }
        }
      }
    }},


// handleDateSelection() {
    //   for (var i = 0; i < this.leaveCategories.length; i++) {
    //     var lr = this.totalLeaves - this.leavesTaken;
    //     console.log(lr, "lrr");

    //     if (this.leaveCategory == this.leaveCategories[i].leaveCategory) {
    //       if (
    //         this.dateArray.length > this.leaveCategories[i].totalLeaves &&
    //         this.leaveType == "Fullday"
    //       ) {
    //         this.isDateCorrect = false;
    //         this.msg = "You can only select " + lr + " number of day(s)";
    //         this.showsnackbar = true;
    //       } else if (
    //         this.dateArray.length > 2 * this.leaveCategories[i].totalLeaves &&
    //         (this.leaveType == "FN" || this.leaveType == "AN")
    //       ) {
    //         this.isDateCorrect = false;
    //         this.msg = "You can only select " + lr + " number of day(s)";
    //         this.showsnackbar = true;
    //       } else this.isDateCorrect = true;
    //     }
    //   }
    // },
    formatDate(date) {
      return date.toLocaleDateString();
    },
    getleaves() {
      this.visible = true;
      // var data1 = {};
      // data1["orgId"] = this.orgId;
      var id;
      if (this.appliedBy == "me") {
        id = this.userId;
        this.uid = "";
      } 
      else if (this.appliedBy == "others") id = this.uid;

      axios({
        method: "POST",
        url: "/employee/leave/details/view/" + id,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // console.log(response.data.data)
          if (response.data.status === true) {
            this.visible = false;
            this.leaveCategories = response.data.data.leaveDetails;
            this.leaveCategory = this.leaveCategories[0].leaveCategory;
            this.totalLeaves = response.data.totalAllowedLeaves;
            this.leavesTaken = response.data.totalAvailedLeaves;
          } else if (response.data.status === false) {
            this.visible = false;
          }
        })
        .catch((e) => {
          this.visible = false;
          this.errors.push(e);
          this.msg = "failed";
        });
    },
    getTeamList(val) {
      this.visible = true;
      // var data1 = {};
      // data1["orgId"] = this.orgId;
      axios({
        method: "GET",
        url: "/team/members/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          empId: this.userId,
          searchKeyword: val,
        },
      })
        .then((response) => {
          // console.log(response.data.data)
          if (response.data.status === true) {
            this.visible = false;
            this.teamMembers = response.data.data;
          } else if (response.data.status === false) {
            this.visible = false;
          }
        })
        .catch((e) => {
          this.visible = false;
          this.errors.push(e);
          this.msg = "failed";
        });
    },
    applyLeave() {
      var data1 = {};
      var dateArray = [];
      for (var i = 0; i < this.dateArray.length; i++) {
        var d = this.dateArray[i]; //your date object
        var newDate = new Date(d).setHours(5, 30, 0, 0);
        newDate = newDate.toString();
        newDate = newDate.split(" ").slice(0, 5).join(" ");
        dateArray.push(d);
      }
      data1["reason"] = this.reason;
      data1["leaveType"] = this.leaveType;
      data1["leaveCategory"] = this.leaveCategory;
      data1["leaveRequiredOn"] = dateArray;
      if (this.appliedBy === "others") {
    data1["uid"] = this.uid;
  }
      var url;
      if (this.appliedBy == "me") url = "/employee/apply/leave";
      else if (this.appliedBy == "others")
        url = "/employee/apply/another/employee/leave";

      if (this.isDateCorrect) {
        axios({
          method: "POST",
          url: url,
          data: data1,
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.visible = false;
            this.status = response.data.status;
            this.msg = response.data.msg;
            if (response.data.status === true) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.$router.push({ name: "myLeaves" });
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.visible = false;
            if (err.response.data.status === false) {
              this.msg = "Please Try Again";
            }
          });
      } else {
        this.msg =
          "You can only select " +
          (this.totalLeaves - this.leavesTaken) +
          " number of day(s)";
        this.showsnackbar = true;
      }
    },
    formatDatePicker(date) {
      var dt = new Date(date);
      var day = String(dt.getDate()).padStart(2, "0"); // Ensure two digits
      var month = String(dt.getMonth() + 1).padStart(2, "0"); // Ensure two digits
      var year = dt.getFullYear();
      var strTime = day + "-" + month + "-" + year;
      return strTime;
    },
  },
};
</script>

